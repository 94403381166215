export const users = [
  {
    username: "u",
    password: "p",
    key: "b0246378-c779-4f8c-bc3c-7bd098c8057e",
    value: "55b809d1-2a59-4ff6-ba57-46748450a8b5",
  },
  {
    username: "hamu",
    password: "hamucopiessmarty",
    key: "23fdc246-ca43-44aa-b600-61712046d018",
    value: "807c3b5a-ea98-4983-8eda-8708c933a1fd",
  },
  {
    username: "sstoor",
    password: "sstoor",
    key: "5f73b0d4-9196-4906-8a7e-601939c02165",
    value: "21c21be1-eace-44ed-b6d0-9becda3e246a",
  },
];
